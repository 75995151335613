import { gql } from '../generated';

// These fragments are registered on the graphql client cache and available to all queries.
// They should be used for fields that can't be merged due to not having an ID or merge function.
// In future we should split the queries and fragments between server and client queries
// if not all of a fragment is used in either.
export const registeredFragments = gql(`
  fragment VariantCostSummaryInitialFields on PricePlanInitialCostSummary {
    totalAmount {
      valueAfterTax {
        value
      }
    }
  }

  fragment VariantCostSummaryRecurringFields on PricePlanRecurringCostSummary {
    totalAmount {
      valueAfterTax {
        value
      }
    }
    insuranceAmount {
      valueAfterTax {
        value
      }
    }
  }
`);
