import { rayloLight } from '../rayloLight';
import { primaryColors, rayloPayColors } from './colours';

export const footer = {
  ...rayloLight.footer,
  backgroundColor: '#1a2156',
  linkColor: primaryColors.w01,
  headerColor: rayloPayColors.lightBlue,
  rayloPayLogoColor: primaryColors.w01,
};
