import { secondaryColors } from './../affordableMobiles/colours';
import { primaryColors } from '../../variables/colours';

export const productDetailsPage = {
  mobileImageSection: {
    backgroundColor: primaryColors.b01,
    color: primaryColors.w01,
    conditionNew: secondaryColors.b04,
    conditionRefurb: primaryColors.p01,
  },
};
