import { primaryColors, secondaryColors } from './../../variables/colours';
import { rayloLight } from '../rayloLight';

export const footer = {
  ...rayloLight.footer,
  backgroundColor: primaryColors.c01,
  linkColor: primaryColors.w01,
  headerColor: secondaryColors.b03,
  rayloPayLogoColor: primaryColors.w01,
};
