import { ComponentProps } from 'react';
import NextLink from 'next/link';

/**
 * Disable prefetching by default, otherwise all `<NextLink/>` components within the
 * viewport are prefetched on load. Links will still be prefetched on hover with this
 * setup.
 */
export const Link = (props: ComponentProps<typeof NextLink>) => {
  return <NextLink prefetch={false} {...props} />;
};
