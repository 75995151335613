import { primaryColors } from './colours';

export const productDetailsPage = {
  mobileImageSection: {
    backgroundColor: '#f4f4f4',
    color: primaryColors.c01,
    conditionNew: primaryColors.p01,
    conditionRefurb: primaryColors.o01,
  },
};
