import React from 'react';
import { StyledSectionMessageBase } from './SectionMessageBase.styles';
import { ISectionMessageBase } from './SectionMessageBase.types';
import Container from '../../../elements/Container';
import { primaryColors, secondaryColors } from '../../../colors';
import { px2Rem } from '../../../utils/px2Rem';
import Icon from '../../../elements/Icon';
import Copy from '../../../elements/Copy';
import TextLink from '../../../elements/TextLink';
import Spacer from '../../../elements/Spacer';
import BulletList from '../../../components/BulletList';

export const SectionMessageBase = (
  {
    dataTestId,
    display,
    header,
    textLink,
    copy,
    content,
    linkOnClick,
    minWidth,
    height,
    bulletList,
    backgroundColor = secondaryColors.b05,
    borderColor = secondaryColors.b04,
    children,
    minHeight,
    borderRadius = 4,
    padding,
    alignCenter,
    className,
  }: ISectionMessageBase) => (
    <StyledSectionMessageBase
      className={className}
      data-testid={dataTestId}
      display={display}
      height={height}
    >
      <Container
        width="initial"
        minWidth={minWidth}
        backgroundColor={backgroundColor}
        border={borderColor}
        height={height}
        minHeight={minHeight}
        padding={padding ?? px2Rem(16)}
        styles={{
          borderRadius: px2Rem(borderRadius),
        }}
      >
        <Container flexRow alignCenter={alignCenter}>
          <Container margin="auto">
            {header && (
              <Copy fontSize={14} color={primaryColors.c01} bold lineHeight={20}>
                {header}
              </Copy>
            )}
            {copy && (
              <Copy fontSize={14} color={primaryColors.c01}>
                {copy}
              </Copy>
            )}
            {content && <>{content}</>}
            {textLink && (
              <TextLink
                fontColor={primaryColors.b01}
                fontSize={14}
                onClick={linkOnClick}
                lineHeight={20}
                noUnderline
              >
                {textLink}
              </TextLink>
            )}
            {bulletList && (
              <>
                <Spacer height={8} />
                <BulletList
                  items={bulletList}
                  paddingRight={8}
                  bulletIcon="Ellipse24RayloBlue"
                />
              </>
            )}
          </Container>
          {children}
        </Container>
      </Container>
    </StyledSectionMessageBase>
);
