import styled from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';
import { IStyledLink } from './TextLink.types';
import { CSS_FONT_WEIGHT } from '../../constants';

const StyledTextLink = styled.span<IStyledLink>`
  color: ${({ theme }) => theme?.typography?.textLink?.color};
  text-decoration: underline;
  font-size: ${px2Rem(16)};
  cursor: pointer;
  ${({ fontSize }) => fontSize && `font-size: ${px2Rem(fontSize)};`}
  ${({ lineHeight }) => lineHeight && `line-height: ${px2Rem(lineHeight)};`}
  ${({ fontColor }) => fontColor && `color: ${fontColor};`}
  ${({ regular }) => regular && `font-weight: ${CSS_FONT_WEIGHT.vars.regular};`}
  ${({ bold }) => bold && `font-weight: ${CSS_FONT_WEIGHT.vars.bold};`}
  ${({ noUnderline }) => noUnderline && 'text-decoration: none;'}
`;

export { StyledTextLink };
