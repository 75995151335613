import { navMapping } from './navigationHelpers';
import pluralize from 'pluralize';

const lowercaseNavKeyMapping = Object.fromEntries(Object.entries(navMapping).map(([k, v]) => ([k.toLowerCase(), k])));
const reverseNavMapping = Object.fromEntries(Object.entries(navMapping).map(([k, v]) => ([v, k])));

const getCategoryName = (category: string): string => {
  return reverseNavMapping[category] ?? lowercaseNavKeyMapping[category] ?? category;
};

const pluralizeWithCase = (displayName: string) => {
  const isUpperCaseWord = displayName === displayName?.toUpperCase();

  if (isUpperCaseWord) {
    return pluralize(displayName).replace(/S$/, 's');
  } else {
    return pluralize(displayName);
  }
};

export { getCategoryName, pluralizeWithCase };
