import { primaryColors } from './colours';

export const checkoutBar = {
  button: {
    backgroundColor: primaryColors.o01,
    borderRadius: '50px',
    hover: {
      backgroundColor: '#ff9b55',
      opacity: '1',
    },
  },
};
