import { uSwitchColors } from './colours';

export const typography = {
  subHeader: {
    color: uSwitchColors.navy,
  },
  textLink: {
    color: uSwitchColors.navy,
  },
};
