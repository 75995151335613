import { primaryColors } from '../../variables/colours';
import { uSwitchColors } from './colours';

export const aboutUsPage = {
  heroSection: {
    backgroundColor: uSwitchColors.blue,
    copyColor: uSwitchColors.navy,
    button: {
      borderRadius: '0',
      color: primaryColors.w01,
      background: uSwitchColors.navy,
    },
  },
  ourCustomers: {
    headerColor: uSwitchColors.navy,
    copyColor: uSwitchColors.navy,
  },
  whyLease: {
    headerColor: uSwitchColors.navy,
    copyColor: uSwitchColors.navy,
  },
  howItWorks: {
    stepDisplay: {
      backgroundColor: uSwitchColors.navy,
    },
    headerColor: uSwitchColors.navy,
  },
};
