const PhonesIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7097_675)">
      <circle cx="24" cy="24" r="24" fill="#F4F4F4" />
      <path d="M28.8012 14H19.1985C18.2378 14 17.459 14.7788 17.459 15.7396V32.2604C17.459 33.2212 18.2378 34 19.1985 34H28.8012C29.762 34 30.5408 33.2212 30.5408 32.2604V15.7396C30.5408 14.7788 29.762 14 28.8012 14Z" stroke="#ED7C7C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M26.6843 16.9351H21.3154C21.09 16.9354 20.8719 16.8548 20.7008 16.708C20.5298 16.5612 20.417 16.358 20.3829 16.1351L20.0674 14H27.9323L27.6167 16.1333C27.5831 16.3565 27.4705 16.5602 27.2994 16.7073C27.1282 16.8545 26.91 16.9353 26.6843 16.9351V16.9351Z" stroke="#ED7C7C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_7097_675">
        <rect width="48" height="48" rx="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PhonesIcon;
