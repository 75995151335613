import NEW_BREAKPOINTS from '@/constants/breakpoints';
import { px2Rem } from '@/utils/px2Rem';
import styled from 'styled-components';

const StyledLayout = styled.div`
  @media (min-width: ${NEW_BREAKPOINTS.smallDesktop}px) {
    padding-bottom: ${px2Rem(96)};
  }
`;

export { StyledLayout };
