enum USwitchColorRange {
  BLUE = '#bee8f3',
  LIGHTBLUE = '#e1f9ff',
  NAVY = '#141424'
}

export const uSwitchColors = {
  blue: USwitchColorRange.BLUE,
  lightBlue: USwitchColorRange.LIGHTBLUE,
  navy: USwitchColorRange.NAVY,
};
