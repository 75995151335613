enum PrimaryColorRange {
  CHARCOAL01 = '#2D2D2D',
  PURPLE = '#4a25aa',
  BLUE01 = '#4252FF',
  ORANGE = '#ff6900',
  WHITE = '#FFF'
}

enum SecondaryColorRange {
  CHARCOAL02 = '#6C6C6C',
  CHARCOAL03 = '#ABABAB',
  CHARCOAL04 = '#E0E0E0',
  CHARCOAL05 = '#F4F4F4',
  BLUE02 = '#717DFF',
  BLUE03 = '#97A0FF',
  BLUE04 = '#C6CBFF',
  BLUE05 = '#F0F1FF',
  PURPLE02 = '#3B1D88',
  PINK02 = '#ED7C7C',
  PINK03 = '#FFBFB6',
  PINK04 = '#FFE6E1',
  PINK05 = '#FFF8F7'
}

enum UIStates {
  ERROR = '#EE0004',
  SUCCESS = '#21831C',
  WARNING = '#FF9901'
}

export const primaryColors = {
  c01: PrimaryColorRange.CHARCOAL01,
  b01: PrimaryColorRange.BLUE01,
  o01: PrimaryColorRange.ORANGE,
  p01: PrimaryColorRange.PURPLE,
  w01: PrimaryColorRange.WHITE,
};

export const secondaryColors = {
  c02: SecondaryColorRange.CHARCOAL02,
  c03: SecondaryColorRange.CHARCOAL03,
  c04: SecondaryColorRange.CHARCOAL04,
  c05: SecondaryColorRange.CHARCOAL05,
  b02: SecondaryColorRange.BLUE02,
  b03: SecondaryColorRange.BLUE03,
  b04: SecondaryColorRange.BLUE04,
  b05: SecondaryColorRange.BLUE05,
  p02: SecondaryColorRange.PURPLE02,
  p03: SecondaryColorRange.PINK03,
  p04: SecondaryColorRange.PINK04,
  p05: SecondaryColorRange.PINK05,
};

export const rayloPayColors = {
  lightBlue: '#6c7efd',
};
