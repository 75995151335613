import Icon from '../../elements/Icon';
import DualWeightCopy from '../dualweightcopy';
import { StyledIconWithText } from './styles';
import { IIconWithText } from './types';

export const IconWithText = ({
  dataTestId,
  icon,
  children,
  fontSize,
  fontColor,
}: IIconWithText) => {
  return (
    <StyledIconWithText data-testid={dataTestId}>
      <Icon name={icon} style={{ flexShrink: 0 }} />
      <DualWeightCopy fontSize={fontSize} fontColor={fontColor}>{children}</DualWeightCopy>
    </StyledIconWithText>
  );
};
