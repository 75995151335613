import React from 'react';
import { primaryColors } from '../../colors';
import Container from '../../elements/Container';
import Copy from '../../elements/Copy';
import Icon from '../../elements/Icon';
import { px2Rem } from '../../utils/px2Rem';
import { IBulletList } from './BulletList.types';

export const BulletList = ({
  dataTestId,
  items,
  paddingRight,
  bulletIcon,
  marginBottom,
}: IBulletList) => {
  return (
    <div data-testid={dataTestId}>
      {items.map((item: string, i: number) => (
        <Container
          flexRow
          key={i}
          margin={`0 0 ${px2Rem(marginBottom ?? 8)} 0`}
        >
          <Container
            fitContent
            padding={`0 ${px2Rem(paddingRight ?? 14)} 0 0`}
            center
            height="17px"
          >
            <Icon name={bulletIcon ?? 'Ellipse24'} />
          </Container>
          <Copy color={primaryColors.c01} fontSize={14}>
            {item}
          </Copy>
        </Container>
      ))}
    </div>
  );
};
