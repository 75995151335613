import { primaryColors, secondaryColors } from '../../variables/colours';
import { uSwitchColors } from './colours';

export const productDetailsPage = {
  mobileImageSection: {
    backgroundColor: secondaryColors.c05,
    color: primaryColors.c01,
    conditionNew: primaryColors.b01,
    conditionRefurb: secondaryColors.p02,
  },
  deliveryDateSection: {
    iconColor: uSwitchColors.navy,
  },
  refurbTrustbuilder: {
    iconColor: uSwitchColors.navy,
  },
};
