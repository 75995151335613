import { StyledSideNav, StyledSideNavOverlay } from './SideNav.styles';
import { SideNavSubMenu } from '../SideNavSubMenu/SideNavSubMenu';
import { SideNavProps, SubMenuType } from '../Navbar.types';
import { CloseButton } from '../utils/utilComponents';

export const SideNav = ({
  isOpen, onClose, navData, subMenuOpen, setSubMenuOpen, setSideNavOpen, accountHomeUrl, productsUrl, LinkComponent,
}: SideNavProps) => {
  const setSubMenu = (subMenuType: SubMenuType, subMenuOption?: string | null) => {
    const menu = `${subMenuType}${subMenuOption ? `-${subMenuOption}` : ''}`;
    setSubMenuOpen(menu);
  };

  return (
    <>
      <StyledSideNavOverlay $isOpen={isOpen} onClick={onClose} />
      <StyledSideNav data-testid="sidenav" $isOpen={isOpen}>
        <div className="side-nav-header">
          Menu
          <CloseButton dataTestId="sidenav-close" onClick={onClose} />
        </div>
        <SideNavSubMenu
          items={navData}
          setSubMenu={setSubMenu}
          setSideNavOpen={setSideNavOpen}
          activeItem={subMenuOpen}
          accountHomeUrl={accountHomeUrl}
          productsUrl={productsUrl}
          LinkComponent={LinkComponent}
        />
      </StyledSideNav>
    </>
  );
};
