import { BREAKPOINTS, ASSETS_FONTS_BASE_URL, CSS_FONT_FAMILY, CSS_FONT_WEIGHT } from '../../../constants';
import { primaryColors, secondaryColors } from '../../../colors';
import { px2Rem } from '../../../utils/px2Rem';
import styled, { css } from 'styled-components';

const fontFamilyNames = {
  sans: 'Raylo',
  mono: 'Raylo-MonoSpaced',
};

const fontWeights = {
  regular: 400,
  medium: 500,
  bold: 700,
};

export const FontsWrapper = styled.div`
  @font-face {
    font-family: "${fontFamilyNames.sans}";
    font-weight: ${fontWeights.regular};
    src: url("${ASSETS_FONTS_BASE_URL}/FKGroteskRaylo-Regular.woff2") format('woff2'),
        url("${ASSETS_FONTS_BASE_URL}/FKGroteskRaylo-Regular.woff") format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: "${fontFamilyNames.sans}";
    font-weight: ${fontWeights.medium};
    src: url("${ASSETS_FONTS_BASE_URL}/FKGroteskRaylo-Medium.woff2") format('woff2'),
        url("${ASSETS_FONTS_BASE_URL}/FKGroteskRaylo-Medium.woff") format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: "${fontFamilyNames.sans}";
    font-weight: ${fontWeights.bold};
    src: url("${ASSETS_FONTS_BASE_URL}/FKGroteskRaylo-Bold.woff2") format('woff2'),
        url("${ASSETS_FONTS_BASE_URL}/FKGroteskRaylo-Bold.woff") format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: "${fontFamilyNames.mono}";
    src: url("${ASSETS_FONTS_BASE_URL}/FKGroteskRaylo-Monospaced.woff2") format('woff2'),
        url("${ASSETS_FONTS_BASE_URL}/FKGroteskRaylo-Monospaced.woff") format('woff');
    font-display: swap;
  }
  ${CSS_FONT_FAMILY.properties.sans}: ${fontFamilyNames.sans}, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  ${CSS_FONT_FAMILY.properties.mono}: ${fontFamilyNames.mono}, monospace;
  ${CSS_FONT_WEIGHT.properties.regular}: ${fontWeights.regular};
  ${CSS_FONT_WEIGHT.properties.medium}: ${fontWeights.medium};
  ${CSS_FONT_WEIGHT.properties.bold}: ${fontWeights.bold};

  font-family: var(${CSS_FONT_FAMILY.properties.sans});
`;

export const StyledNavBarWrapper = styled.div<{ $hideNav: boolean }>`
  position: fixed;
  width: 100%;
  z-index: 998;
  box-sizing: border-box;
  top: ${({ $hideNav }) => $hideNav ? '-100%' : 0};
  left: 0;
  background-color: ${primaryColors.b01};
  padding: 0 ${px2Rem(24)};
  transition: top 0.8s ease;

  a, button {
    cursor: pointer;
    text-decoration: none!important;

    &:hover {
      text-decoration: none!important;
    }
  }

  li {
    min-height: auto!important;
    margin-bottom: 0!important;
  }
`;

export const StyledNavBar = styled.header`
  display: flex;
  width: 100%;
  padding: ${px2Rem(24)} 0;

  .trustpilot-nav-img {
    margin-left: ${px2Rem(16)};

    @media (max-width: 375px) {
      display: none;
    }
  }

  .nav-primary-buttons {
    margin-left: auto;
    display: flex;
    align-items: center;

    button {
      margin-right: ${px2Rem(24)};

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .quick-links {
    display: flex;
    align-items: center;
    gap: ${px2Rem(24)};

    .dropdown {
      display: none;

      @media (min-width: ${BREAKPOINTS.smallDesktop}px) {
        display: block;
      }
    }

    .text-link {
      display: none;
    }

    @media (min-width: ${BREAKPOINTS.smallDesktop}px) {
      color: ${primaryColors.w01};
      font-size: ${px2Rem(14)};
      line-height: ${px2Rem(17)};
      gap: ${px2Rem(16)};

      a:not(.dropdown a):not(.login) {
        text-decoration: none;
        color: inherit;
        padding: ${px2Rem(7.5)} ${px2Rem(8)};
        border-radius: ${px2Rem(4)};
        transition: background-color 0.3s ease-out;
        background-color: transparent;

        &:hover {
          background-color: ${secondaryColors.b02};
        }

        &.text-link {
          display: block;
        }
      }

      .dropdown li {
        font-size: ${px2Rem(14)}!important;
        line-height: ${px2Rem(17)}!important;
      }
    }
  }
`;

export const StyledBurgerNavButton = styled.button<{ $sideNavOpen: boolean }>`
  ${({ $sideNavOpen }) => `
    appearance: none;
    background: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
    display: block;
    height: ${px2Rem(24)};
    width: ${px2Rem(24)};


    @media (min-width: ${BREAKPOINTS.smallDesktop}px) {
      display: none;
    }

    span {
      background-color: ${primaryColors.w01};
      height: ${px2Rem(2)};
      width: ${px2Rem(22)};
      display: block;
      margin-bottom: ${px2Rem(5)};
      transform: scaleX(${$sideNavOpen ? 0 : 1});
      transition: transform 0.3s ease;

      &:nth-child(odd) {
        transform-origin: 100% 50%;
      }  

      &:nth-child(even) {
        transform-origin: 0 50%;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  `}
`;

export const navItemHoverStyles = css`
  border-radius: ${px2Rem(4)};
  padding: ${px2Rem(8)};
  background-color: transparent;
  transition: background-color 0.3s ease-out;

  &:hover {
    background-color: ${secondaryColors.b02};
  }
`;

export const StyledDesktopItems = styled.div`
  display: none;

  .burger-desktop {
    ${navItemHoverStyles};
    display: flex;
    align-items: center;
    cursor: pointer;
    align-items: center;
    font-size: ${px2Rem(14)};

    .burger-nav-button {
      display: block;
      height: ${px2Rem(16)};
      width: ${px2Rem(16)};

      span {
        width: ${px2Rem(16)};

        &:not(:nth-of-type(3)) {
          margin-bottom: ${px2Rem(3)};
        }
      }
    }

    p {
      font-size: ${px2Rem(14)};
      line-height: ${px2Rem(17)};
    }
  }

  p {
    margin: 0 0 0 ${px2Rem(8)};
  }

  .divider {
    background-color: ${secondaryColors.b02};
    height: ${px2Rem(32)};
    width: ${px2Rem(1)};
    margin: 0 ${px2Rem(8)};
  }

  .category-links-desktop {
    display: flex;
    gap: ${px2Rem(8)};
  }

  @media (min-width: ${BREAKPOINTS.smallDesktop}px) {
    display: flex;
    align-items: center;
    color: ${primaryColors.w01};
    padding: ${px2Rem(11.5)} 0;

    a {
      text-decoration: none;
      color: inherit;
      font-size: ${px2Rem(14)};
      line-height: ${px2Rem(17)};
      ${navItemHoverStyles};
    }
  }
`;

export const StyledLoginIcon = styled.a`
  display: block;
  width: ${px2Rem(24)};
  height: ${px2Rem(24)};
  padding: 0;

  svg path {
    fill: ${primaryColors.w01};
    transition: fill 0.3s ease;
  }

  &:hover {
    background-color: transparent;

    svg path {
      fill: ${secondaryColors.b04};
    }
  }
`;
