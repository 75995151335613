import { rayloLight } from '../rayloLight';
import { secondaryColors, primaryColors } from './colours';

export const navBar = {
  ...rayloLight.navBar,
  backgroundColor: primaryColors.p01,
  bottomBorder: 'none',
  rayloPayLogoColor: primaryColors.w01,
  linkColor: primaryColors.w01,
  hoverLinkColor: primaryColors.w01,
  disabledLinkColor: secondaryColors.c03,
  menuBarsColor: primaryColors.w01,
};
