export const icons = {
  RpBadge: 'RpBadge.svg',
  RpBadgeThin: 'RPBadgeThin.svg',
  Ellipse24: 'Ellipse24.svg',
  Ellipse24RayloBlue: 'Ellipse24RayloBlue.svg',
  Info: 'icon-custom-info.svg',
  RPLogo: 'RPLogo.svg',
  Close: 'close.svg',
  Laptop: 'Laptop.svg',
  Rocket: 'Rocket.svg',
  Wallet: 'Wallet.svg',
  Dashboard: 'Dashboard.svg',
  Ecology: 'Ecology.svg',
  Insurance: 'Insurance.svg',
  PercentageCircle: 'PercentageCircle.svg',
  Stopwatch: 'Stopwatch.svg',
  TrustPilot: 'trustpilot.svg',
  RPLogoLight: 'r-plogo.svg',
  CustomClose: 'icon-custom-close.svg',
  ShieldLockRayloBlue: 'icon-streamline-shield-lock.svg',
  ConsentsLogo: 'consents-logo.svg',
  RayloLogo: 'raylo-logo.svg',
  CustomerSupport: 'icon-streamline-customer-support-human.svg',
  Calculator: 'calculator.svg',
  Warranty: 'warranty.svg',
  Phones: 'phones.svg',
  Watches: 'watches.svg',
  Tablets: 'tablets.svg',
  Headphones: 'headphones.svg',
  Laptops: 'laptops.svg',
  Desktops: 'desktops.svg',
  Consoles: 'consoles.svg',
  TVs: 'tvs.svg',
  Filter: 'Filter.svg',
  CheckCircle: 'check-circle.svg',
  ChevronDown: 'chevron-down.svg',
  Form: 'form.svg',
  XCircle: 'x-circle.svg',
  RayloDesignRpLogo: 'logo-raylo.svg',
  RayloDesignCustomClose: 'icon-custom-close.svg',
  RayloDesignWallet: 'icon_custom_rp_wallet.svg',
  RayloDesignRocket: 'icon_custom_rp_rocket.svg',
  RayloDesignLaptop: 'icon_custom_rp_laptop.svg',
  RayloDesignInfo: 'icon_custom_info.svg',
  RayloDesignWarning: 'icon_custom_warning.svg',
  RayloDesignCertified: 'logo_b-corp.svg',
  RayloDesignDelivery: 'icon_streamline_delivery.svg',
  Success: 'success.svg',
  FilterLabelClose: 'filter-label-close.svg',
  PreApproved: 'pre-approved.svg',
  ArrowRight: 'arrow-right.svg',
  ArrowLeft: 'white-arrow-left.svg',
  ArrowLeftDisabled: 'arrow-left-disabled.svg',
};
