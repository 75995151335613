import { rayloLight } from '../rayloLight';
import { primaryColors } from './colours';

export const productIndexPage = {
  ...rayloLight.productIndexPage,
  catDisplayCard: {
    ...rayloLight.productIndexPage.catDisplayCard,
    backgroundColour: primaryColors.p01,
  },
  button: {
    hover: {
      backgroundColour: '#ff9b55',
    },
  },
};
