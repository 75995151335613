import { uSwitchColors } from './colours';
import { primaryColors, secondaryColors } from './../../variables/colours';
import { rayloLight } from '../rayloLight';

export const navBar = {
  ...rayloLight.navBar,
  backgroundColor: primaryColors.w01,
  bottomBorder: `solid 1px ${secondaryColors.c04}`,
  rayloPayLogoColor: primaryColors.b01,
  linkColor: primaryColors.c01,
  hoverLinkColor: primaryColors.c01,
  disabledLinkColor: secondaryColors.c03,
  menuItemsBackgroundColor: primaryColors.w01,
  menuBarsColor: uSwitchColors.navy,
};
