import { px2Rem } from '@/utils/px2Rem';
import styled from 'styled-components';

const StyledIconWithText = styled.div`
  display: flex;
  align-items: center;
  gap: ${px2Rem(12)}
`;

export { StyledIconWithText };
