import { ICategoryMap, NavMap } from '../Navbar.types';

export const aboutSubMenuLinks = [
  {
    displayName: 'About us',
    link: 'https://www.raylo.com/about',
  },
  {
    displayName: 'Blog',
    link: 'https://www.raylo.com/blog',
  },
  {
    displayName: 'Sustainability',
    link: 'https://www.raylo.com/sustainability',
  },
  {
    displayName: 'Careers',
    link: 'https://www.raylo.com/careers',
  },
];

export const helpSubMenuLinks = [
  {
    displayName: 'Help centre',
    link: 'https://help.raylo.com/',
  },
  {
    displayName: 'Contact us',
    link: 'https://help.raylo.com/en/articles/3271547-contact-raylo',
  },
];

export const navMapping = {
  Laptops: 'computers',
  Desktops: 'computers',
  Phones: 'phones',
  Tablets: 'tablets',
  Consoles: 'consoles',
  Watches: 'watches',
  Headphones: 'headphones',
  TVs: 'tvs'
} as NavMap;

const productNavMapping = {
  computers: {
    url: '/products?category=laptops&category=desktops',
  },
} as ICategoryMap;

export const categoryForUrl = (category: string) => {
  return productNavMapping[category]?.url || `/products?category=${category.toLowerCase()}`;
};
