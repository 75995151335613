import { primaryColors } from '../affordableMobiles/colours';
import { uSwitchColors } from './colours';

export const breadCrumbs = {
  color: uSwitchColors.navy,
  iconColor: uSwitchColors.navy,
  mobile: {
    color: uSwitchColors.navy,
  },
};
