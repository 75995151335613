import { ApolloClient, ApolloLink, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { httpLink } from './apolloHttpLink';
import { clientAuthTokenProvider } from '../auth/clientAuth';
import { getUserAuthTokenProvider } from '../auth/userAuth';
import { AuthTokenInfo } from '../auth/authTokenInfo';
import { createFragmentRegistry } from '@apollo/client/cache';
import { registeredFragments } from '@/graphql/operations/@fragments.graphql';

type UserAuthLinkParams = {
  authToken: AuthTokenInfo;
};

const getUserAuthLink = ({ authToken }: UserAuthLinkParams) => {
  const userAuthTokenProvider = getUserAuthTokenProvider(authToken);
  return setContext(async (_, { headers }) => {
    await userAuthTokenProvider.renewIfNeeded();
    return {
      headers: {
        ...headers,
        ['X-User-Token']: userAuthTokenProvider.token.value,
      },
    };
  });
};

const clientAuthLink = setContext(async (_, { headers }) => {
  await clientAuthTokenProvider.renewIfNeeded();
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${clientAuthTokenProvider.token.value}`,
    },
  };
});

const logLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((result) => {
    if (result.errors) {
      console.error(operation.operationName, operation.variables, result);
    }
    return result;
  });
});

type MakeApolloClientParams = UserAuthLinkParams;
export const getUserClient = (authTokenInfo: MakeApolloClientParams) => new ApolloClient({
  link: from([clientAuthLink, getUserAuthLink(authTokenInfo), httpLink]),
  cache: new InMemoryCache({
    fragments: createFragmentRegistry(registeredFragments),
  }),
});

const serverClient = new ApolloClient({
  link: from([clientAuthLink, logLink, httpLink]),
  cache: new InMemoryCache({
    fragments: createFragmentRegistry(registeredFragments),
  }),
});

export default serverClient;
