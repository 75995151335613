import { secondaryColors } from '../../variables/colours';

export const productIndexPage = {
  catDisplayCard: {
    backgroundColour: secondaryColors.c04,
  },
  button: {
    hover: {
      backgroundColour: '#ff9b55',
    },
  },
};
