import constate from 'constate';
import { useCookies } from 'react-cookie';
import { CheckoutContext } from '../types/checkoutContext';

const rayloCookieNames = [
  'raylo_checkoutToken',
  'raylo_checkoutContext',
  'raylo_userToken',
  'raylo_userTokenExpiresAt',
  'raylo_subscriptionId',
];

const filterCookies = (allCookies: { [key: string]: string }) => {
  return Object.fromEntries(rayloCookieNames.filter(name => allCookies[name]).map(name => [name, allCookies[name]]));
};

const useRayloCookies = () => {
  const [cookies, set, _remove] = useCookies(rayloCookieNames);

  const cookieValues = filterCookies(cookies) as { [key in keyof typeof cookies]: string | CheckoutContext | undefined };

  const setDomainCookie = (name: string, value: string | undefined) => {
    const cookieName = `raylo_${name}`;
    set(cookieName, value, { path: '/', domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN, secure: true, sameSite: 'none' });
  };

  return {
    cookieValues,
    setDomainCookie,
  };
};

const [RayloCookiesProvider, useRayloCookiesContext] = constate(useRayloCookies);
export { RayloCookiesProvider, useRayloCookiesContext };
