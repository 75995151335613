import { px2Rem } from '../../../utils/px2Rem';
import { Dispatch, SetStateAction } from 'react';
import Button from '../../../designSystem/components/Button';
import TextAccordion from '../../TextAccordion';
import { MenuLinks, MenuItem } from '../utils/utilComponents';
import { primaryColors, secondaryColors } from '../../../colors';
import { aboutSubMenuLinks, helpSubMenuLinks } from '../utils/utils';
import SectionMessageWithIcon from '../../../designSystem/components/SectionMessageWithIcon';
import { StyledQuickLinks } from './NavBarQuickLinks.styles';

export const NavbarQuickLinks = ({ accountHomeUrl, setSideNavOpen }: { accountHomeUrl: string; setSideNavOpen: Dispatch<SetStateAction<boolean>> }) => {
  const howItWorksOnClick = () => {
    setSideNavOpen(false);
    window.location.assign('https://www.raylo.com/#how-it-works');
  };

  return (
    <StyledQuickLinks>
      <MenuItem
        onClick={howItWorksOnClick}
        label="How it works"
        staticIcon="nav-recycle"
        iconSize={24}
        isBold
        border
      />
      <TextAccordion
        dataTestId="about-accordion"
        primaryColour={primaryColors.c01}
        data={[{ title: 'About', body: <MenuLinks links={aboutSubMenuLinks} /> }]}
        boldHeaders
        showDivider
        iconSize={16}
        headerBackgroundHoverColor={secondaryColors.c05}
        isSubMenu
        iconWidth={3}
        iconPaddingLeft={0}
      />
      <TextAccordion
        dataTestId="help-accordion"
        primaryColour={primaryColors.c01}
        data={[{ title: 'Help', body: <MenuLinks links={helpSubMenuLinks} /> }]}
        boldHeaders
        showDivider
        iconSize={16}
        headerBackgroundHoverColor={secondaryColors.c05}
        isSubMenu
        iconWidth={3}
        iconPaddingLeft={0}
      />
      <div style={{ padding: px2Rem(24) }}>
        <div style={{ marginBottom: px2Rem(24) }}>
          <Button onClick={() => window.location.assign(`${accountHomeUrl}/account`)}>
            Login
          </Button>
        </div>
        <SectionMessageWithIcon
          backgroundColor={secondaryColors.b05}
          borderColor={secondaryColors.b04}
          copy="Raylo is proud to be B Corporation Certified"
          icon="RayloDesignCertified"
          iconLeftAlign
        />
      </div>
    </StyledQuickLinks>
  );
};
