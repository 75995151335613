import { primaryColors, secondaryColors } from '../../variables/colours';
import { px2Rem } from '../../../utils/px2Rem';

export const button = {
  background: {
    primary01: primaryColors.p01,
    primary02: secondaryColors.p02,
    primary03: primaryColors.b01,
  },
  hover: {
    primary01: secondaryColors.p04,
    primary03: secondaryColors.b02,
    primary02: primaryColors.p01,
  },
  fontColor: {
    black: '#000',
    white: '#FFF',
    primary01: primaryColors.c01,
    primary02: primaryColors.c01,
    primary03: primaryColors.w01,
  },
  active: {
    primary01: primaryColors.p01,
    primary02: secondaryColors.p02,
    primary03: primaryColors.b01,
  },
  disabled: {
    background: secondaryColors.c05,
    color: primaryColors.w01,
    opacity: '0.4',
  },
  ghostType: {
    disabled: {
      background: 'none',
      color: secondaryColors.c03,
      border: `solid ${px2Rem(1)} ${secondaryColors.c03}`,
      opacity: '0.4',
    },
    color: primaryColors.c01,
    background: 'none',
    border: `solid ${px2Rem(1)} ${primaryColors.c01}`,
    hover: {
      border: `solid ${px2Rem(1)} rgba(255, 255, 255)`,
    },
    active: {
      border: `solid ${px2Rem(1)} ${primaryColors.c01}`,
    },
  },
};
