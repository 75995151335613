import { primaryColors } from './colours';

export const typography = {
  subHeader: {
    color: primaryColors.p01,
  },
  textLink: {
    color: primaryColors.p01,
  },
};
