import React from 'react';

const WatchesIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7097_724)">
      <circle cx="24" cy="24" r="24" fill="#F4F4F4" />
      <path d="M26.0003 34H22.0003C21.6467 34 21.3076 33.8595 21.0575 33.6095C20.8075 33.3594 20.667 33.0203 20.667 32.6667V30H27.3337V32.6667C27.3337 33.0203 27.1932 33.3594 26.9431 33.6095C26.6931 33.8595 26.3539 34 26.0003 34Z" stroke="#ED7C7C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.0003 14H26.0003C26.3539 14 26.6931 14.1405 26.9431 14.3905C27.1932 14.6406 27.3337 14.9797 27.3337 15.3333V18H20.667V15.3333C20.667 14.9797 20.8075 14.6406 21.0575 14.3905C21.3076 14.1405 21.6467 14 22.0003 14V14Z" stroke="#ED7C7C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M27.3333 18H20.6667C19.1939 18 18 19.1939 18 20.6667V27.3333C18 28.8061 19.1939 30 20.6667 30H27.3333C28.8061 30 30 28.8061 30 27.3333V20.6667C30 19.1939 28.8061 18 27.3333 18Z" stroke="#ED7C7C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_7097_724">
        <rect width="48" height="48" rx="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default WatchesIcon;
