import { StyledSideNavSubMenu, StyledSideNavSubMenuWrapper } from './SideNavSubMenu.styles';
import { MenuItem, NavigateBack } from '../utils/utilComponents';
import { SideNavSubMenuProps, SubMenuType } from '../Navbar.types';
import { categoryForUrl } from '../utils/utils';
import { NavbarQuickLinks } from '../NavBarQuickLinks/NavBarQuickLinks';
import { useRef } from 'react';

const menuOrder = ['categories', 'makes', 'models'];

export const SideNavSubMenu = ({
  items,
  setSubMenu,
  activeItem,
  accountHomeUrl,
  productsUrl,
  LinkComponent,
  setSideNavOpen,
}: SideNavSubMenuProps) => {
  const previousMenu = useRef<SubMenuType>('categories');

  const transitionSubmenu = (
    subMenuType: SubMenuType,
    subMenuOption?: string | null
  ) => {
    previousMenu.current = activeItem as SubMenuType;
    setSubMenu(subMenuType, subMenuOption);
  };

  const isGoingBack = menuOrder.indexOf(activeItem) < menuOrder.indexOf(previousMenu.current);

  return (
    <StyledSideNavSubMenuWrapper>
      <StyledSideNavSubMenu
        data-testid="submenu-categories"
        $active={activeItem === 'categories'}
        $zIndex={3}
        $isGoingBack={isGoingBack}
      >
        {items.categories.map(({ name }) => (
          <MenuItem
            dataTestId={`submenu-item-${name}`}
            key={`category-${name}`}
            onClick={() => transitionSubmenu('categories', name)}
            label={name}
            icon={name.toLowerCase()}
            isBold
            productsUrl={productsUrl}
            LinkComponent={LinkComponent}
          />
        ))}
        <NavbarQuickLinks accountHomeUrl={accountHomeUrl} setSideNavOpen={setSideNavOpen} />
      </StyledSideNavSubMenu>

      {items.categories.map(({ makes, name: categoryName }, i) => (
        <StyledSideNavSubMenu
          data-testid={`submenu-${categoryName}`}
          key={`${makes[i]?.name}-${categoryName}-${i}`}
          $active={activeItem === `categories-${categoryName}`}
          $zIndex={2}
          $isGoingBack={isGoingBack}
        >
          <NavigateBack
            onClick={() => transitionSubmenu('categories')}
            title={categoryName}
            titleLink={categoryForUrl(categoryName.toLowerCase())}
          />
          <MenuItem
            dataTestId={`submenu-see-all-${categoryName}`}
            isBold
            border
            label={`See all ${categoryName.toLowerCase()}`}
            onClick={categoryForUrl(categoryName.toLowerCase())}
            productsUrl={productsUrl}
            LinkComponent={LinkComponent}
          />
          {makes.map(({ name: makesName }) => (
            <MenuItem
              key={`${categoryName}-${makesName}`}
              onClick={() => transitionSubmenu('models', `${categoryName}-${makesName}`)}
              label={makesName}
              productsUrl={productsUrl}
              LinkComponent={LinkComponent}
            />
          ))}
        </StyledSideNavSubMenu>
      ))}

      {items.categories.map(({ makes, name: catName }, i) => (
        makes.map(({ models, name: makeName }) => (
          <StyledSideNavSubMenu
            key={`${catName}-${makeName}`}
            $active={activeItem === `models-${catName}-${makeName}`}
            $zIndex={1}
            $isGoingBack={isGoingBack}
          >
            <NavigateBack
              onClick={() => transitionSubmenu('categories', catName)}
              title={`${makeName} ${catName.toLowerCase()}`}
              titleLink={`${categoryForUrl(catName.toLowerCase())}&make=${makeName?.toLowerCase()}`}
            />
            <MenuItem
              dataTestId={`submenu-see-all-${catName}-${makeName}`}
              isBold
              key={catName}
              border
              label={`See all ${makeName} ${catName.toLowerCase()}`}
              onClick={`${categoryForUrl(catName.toLowerCase())}&make=${makeName?.toLowerCase()}`}
              productsUrl={productsUrl}
              LinkComponent={LinkComponent}
            />
            {models.map(({ displayName, slug }) => (
              <MenuItem
                dataTestId={`${catName}-${displayName ?? ''}`}
                key={displayName}
                onClick={`/products/${slug}`}
                label={displayName}
                productsUrl={productsUrl}
                LinkComponent={LinkComponent}
              />
            ))}
          </StyledSideNavSubMenu>
        ))
      ))}
    </StyledSideNavSubMenuWrapper>
  );
};
