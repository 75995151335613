import styled from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';

const StyledPlusMinusIcon = styled.div<{ $size?: number; $paddingLeft?: number }>`
  ${({ $size, $paddingLeft }) => `
    height: ${px2Rem($size ?? 24)};
    width: ${px2Rem($size ?? 24)};
    cursor: pointer;
    margin-left: auto;
    min-width: ${px2Rem($size ?? 24)};
    padding-left: ${px2Rem($paddingLeft ?? 24)};

    svg path {
      transition: transform 0.3s ease;
    }
  `}
`;

export { StyledPlusMinusIcon };
