import { primaryColors } from '../../variables/colours';

export const checkoutBar = {
  button: {
    backgroundColor: primaryColors.b01,
    borderRadius: '0',
    hover: {
      backgroundColor: 'rgba(66, 82, 255, 0.7)',
      opacity: '1',
    },
  },
};
