import { ICategoryMap, NavMap } from '../components/navbar/types';

const navMapping = {
  Laptops: 'computers',
  Desktops: 'computers',
  Phones: 'phones',
  Tablets: 'tablets',
  Consoles: 'consoles',
  Watches: 'watches',
  Headphones: 'headphones',
  TVs: 'tvs',
} as NavMap;

const productNavMapping = {
  computers: {
    url: '/products?category=laptops&category=desktops',
  },
} as ICategoryMap;

const categoryForUrl = (category: string) => {
  return productNavMapping[category]?.url || `/products?category=${category.toLowerCase()}`;
};

export {
  categoryForUrl,
  navMapping
};
