import { createHttpLink } from '@apollo/client';
import { isServer } from '../isServer';

function getFetchInstance() {
  if (typeof fetch === 'function') {
    return fetch;
  } else if (isServer) {
    return require('node-fetch');
  } else {
    throw new Error('fetch is not defined');
  }
}

export const httpLink = createHttpLink({
  uri: `${process.env.NEXT_PUBLIC_API_ENDPOINT}`,
  fetch: getFetchInstance(),
});
