import React from 'react';
import { StyledButton } from './Button.styles';
import { IButton } from './Button.types';

export const Button = ({
  dataTestId,
  type,
  onClick,
  children,
  disabled,
  medium,
  small,
  xsmall,
  secondary,
}: IButton) => {
  return (
    <>
      <StyledButton
        data-testid={dataTestId}
        type={type}
        onClick={onClick}
        secondary={secondary}
        disabled={disabled}
        medium={medium}
        small={small}
        xsmall={xsmall}
      >
        {children}
      </StyledButton>
    </>
  );
};
