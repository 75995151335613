import { StyledDesktopItems, StyledNavBar, StyledNavBarWrapper, FontsWrapper } from './NavBarWrapper.styles';
import AnimatedLogo from '../AnimatedLogo/AnimatedLogo';
import TrustpilotNavImg from '../svgs/trustpilot-navbar';
import { useState, useEffect, useCallback, useRef } from 'react';
import { SideNav } from '../SideNav/SideNav';
import { BurgerNav, LoginIcon, NavLink } from '../utils/utilComponents';
import { categoryForUrl, aboutSubMenuLinks, helpSubMenuLinks } from '../utils/utils';
import { DropdownSubMenu } from '../DropdownSubMenu/DropdownSubMenu';
import { Menu } from '../Navbar.types';
import type { NavDataModel } from '../../../types';

export const NavBarWrapper = ({
  navData,
  accountHomeUrl,
  productsUrl,
  LinkComponent,
}: {
  navData: NavDataModel;
  accountHomeUrl: string;
  productsUrl: string;
  LinkComponent?: React.ElementType;
}) => {
  const [sideNavOpen, setSideNavOpen] = useState<boolean>(false);
  const [subMenuOpen, setSubMenuOpen] = useState<string>('categories');
  const [dropdownOpen, setDropdownOpen] = useState<'About' | 'Help' | null>(null);
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('down');
  const oldScrollY = useRef<number>(0);

  const onClose = () => {
    setSideNavOpen(false);
    setTimeout(() => {
      setSubMenuOpen('categories');
    }, 300);
  };

  useEffect(() => {
    document.documentElement.style.overflow = sideNavOpen ? 'hidden' : 'auto';
  }, [sideNavOpen]);

  const handleScroll = useCallback(() => {
    if (window.scrollY < 136) {
      setScrollDirection('down');
      return;
    }
    if (window.scrollY > oldScrollY.current) {
      setScrollDirection('up');
    } else {
      setScrollDirection('down');
    }
    oldScrollY.current = window.scrollY;
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const toggleDropdown = (dropdown: Menu | null) => {
    setDropdownOpen(dropdownOpen === dropdown ? null : dropdown);
  };

  return (
    <FontsWrapper>
      <StyledNavBarWrapper $hideNav={scrollDirection === 'up'}>
        <StyledNavBar>
          <AnimatedLogo />
          <TrustpilotNavImg className="trustpilot-nav-img" />
          <div className="nav-primary-buttons" style={{ cursor: 'pointer' }}>
            <div className="quick-links">
              <DropdownSubMenu
                title="About"
                items={aboutSubMenuLinks}
                menuOpen={dropdownOpen}
                toggleMenu={toggleDropdown}
              />
              <DropdownSubMenu
                title="Help"
                items={helpSubMenuLinks}
                menuOpen={dropdownOpen}
                toggleMenu={toggleDropdown}
              />
              <a className="text-link" href="https://www.raylo.com/#how-it-works" title="How it works">
                How it works
              </a>
              <LoginIcon accountHomeUrl={accountHomeUrl} />
              <BurgerNav
                dataTestId="burger-mobile"
                onClick={() => setSideNavOpen(true)}
                sideNavOpen={sideNavOpen}
              />
            </div>
          </div>
        </StyledNavBar>
        <StyledDesktopItems>
          <div data-testid="burger-desktop" className="burger-desktop" onClick={() => setSideNavOpen(true)}>
            <BurgerNav sideNavOpen={sideNavOpen} />
            <p>Menu</p>
          </div>
          <div className="divider" />
          <div className="category-links-desktop">
            {navData.categories.map(({ name }) => (
              <NavLink
                dataTestId={`desktop-links-${name}`}
                href={`${productsUrl}${categoryForUrl(name.toLowerCase())}`}
                title={name}
                LinkComponent={LinkComponent}
                key={name}
              />
            ))}
          </div>
        </StyledDesktopItems>
      </StyledNavBarWrapper>
      <SideNav
        isOpen={sideNavOpen}
        onClose={onClose}
        navData={navData}
        subMenuOpen={subMenuOpen}
        setSubMenuOpen={setSubMenuOpen}
        setSideNavOpen={setSideNavOpen}
        accountHomeUrl={accountHomeUrl}
        productsUrl={productsUrl}
        LinkComponent={LinkComponent}
      />
    </FontsWrapper>
  );
};
