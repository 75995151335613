import '../styles/globals.css';
import type { AppProps } from 'next/app';
import { GoogleTagManager } from '@next/third-parties/google';
import { ApolloProvider } from '@apollo/client';
import { getUserClient } from '../utils/apollo/apolloClient';
import { AppProvider } from '../hooks/useAppContext';
import { ContentProvider } from '../hooks/useContentContext';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { CustomerProvider } from '../hooks/useCustomerContext';
import { initialiseGrowthBook } from '../integrations/growthBook/initialiseGrowthBook';
import { useEffect, useMemo, useState } from 'react';
import { CookiesProvider } from 'react-cookie';
import { IntercomProvider } from 'react-use-intercom';
import { RayloCookiesProvider, useRayloCookiesContext } from '../hooks/useRayloCookiesContext';
import { AuthTokenInfo } from '../utils/auth/authTokenInfo';
import { userIsLoggedIn } from '../utils/auth/userAuth';
import { GlobalUIStyles } from '@raylo-tech/raylopay-ui';
import { ProductCategories } from '../types/productTypes';
import { CategoriesProvider } from '../hooks/useCategoriesContext';
import { StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';
import AppLayout from '../components/Layouts/App';
import { RayloIFrameProvider } from '@/hooks/useRayloIFrameContext';
import { NavDataModel } from '@raylo-tech/raylopay-ui/src/types';
import GlobalNavBar from '@/components/GlobalNavBar/GlobalNavBar';

type RayloAppProps = AppProps<{
  /**
   * `categories` is fetched in `getStaticProps` in the PLP and PDP pages, and
   * is then and passed to the `_app.tsx` component via the `pageProps`.
   */
  categories?: ProductCategories;
  navData?: NavDataModel;
}>;

function App({ Component, pageProps, router }: RayloAppProps) {
  const [growthBookAttributesUpdated, setGrowthBookAttributesUpdated] = useState<boolean>(false);
  const growthBook = useMemo(() => initialiseGrowthBook(), []);

  useEffect(() => {
    if (growthBookAttributesUpdated) return;

    const interval = setInterval(function () {
      if (window?.analytics?.user && growthBook) {
        window?.analytics?.identify();
        growthBook.setAttributes({
          domain: window.location.hostname,
          id: window.analytics.user().anonymousId(),
          customerAnalyticsId: window.analytics.user().id(),
        });
        setGrowthBookAttributesUpdated(true);
      }
    }, 100);
    return () => clearInterval(interval);
  }, [growthBookAttributesUpdated, growthBook]);

  return (
    <GrowthBookProvider growthbook={growthBook}>
      <CookiesProvider>
        <RayloCookiesProvider>
          <RayloIFrameProvider>
            <IntercomProvider appId={process.env.NEXT_PUBLIC_INTERCOM_APP_ID!} initializeDelay={2000}>
              <AppWithGrowthbookAndCookies Component={Component} pageProps={pageProps} router={router} />
            </IntercomProvider>
          </RayloIFrameProvider>
        </RayloCookiesProvider>
      </CookiesProvider>
    </GrowthBookProvider>
  );
}

function AppWithGrowthbookAndCookies({ Component, pageProps }: RayloAppProps) {
  const { cookieValues } = useRayloCookiesContext();
  const [customerIsInMobileApp, setCustomerIsInMobileApp] = useState<boolean>(false);
  const { raylo_userToken: rayloUserToken, raylo_userTokenExpiresAt: rayloUserTokenExpiresAt } = cookieValues;
  const authToken = useMemo(
    () => new AuthTokenInfo(rayloUserToken, rayloUserTokenExpiresAt),
    [rayloUserToken, rayloUserTokenExpiresAt]);
  const client = useMemo(
    () => getUserClient({ authToken }),
    [authToken]);

  useEffect(() => {
    setCustomerIsInMobileApp(document.cookie.includes('mobileClient=true'));
  }, []);

  return (
    <ApolloProvider client={client}>
      <CustomerProvider customerIsInMobileApp={customerIsInMobileApp} hasAuthenticatedUser={userIsLoggedIn(authToken)}>
        <AppProvider>
          <ContentProvider>
            <GlobalUIStyles />
            <CategoriesProvider
              categories={pageProps.categories ?? { productCategories: [] }}
            >
              <StyleSheetManager shouldForwardProp={isPropValid}>
                <GlobalNavBar
                  navData={pageProps.navData ?? { categories: [] }}
                  hasAuthenticatedUser={customerIsInMobileApp || userIsLoggedIn(authToken)}
                />
                <AppLayout>
                  <Component {...pageProps} />
                  <GoogleTagManager gtmId="GTM-TB6BVN3" />
                </AppLayout>
              </StyleSheetManager>
            </CategoriesProvider>
          </ContentProvider>
        </AppProvider>
      </CustomerProvider>
    </ApolloProvider>
  );
}

export default App;
