import styled from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';
import { IStyledLink } from './types';

const StyledTextLink = styled.span<IStyledLink>`
  color: ${({ theme }) => theme?.typography?.textLink?.color};
  text-decoration: underline;
  font-size: ${px2Rem(16)};
  cursor: pointer;
  ${({ fontSize }) => fontSize && `font-size: ${px2Rem(fontSize)};`}
  ${({ fontColor }) => fontColor && `color: ${fontColor};`}
`;

export { StyledTextLink };
