import { BREAKPOINTS, CSS_FONT_WEIGHT } from '../../../constants';
import { primaryColors } from '../../../colors';
import { px2Rem } from '../../../utils/px2Rem';
import styled from 'styled-components';

export const StyledSideNavOverlay = styled.div<{ $isOpen: boolean }>`
  ${({ $isOpen }) => `
    display: none;

    @media (min-width: ${BREAKPOINTS.smallDesktop}px) {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
      width: 100vw;
      height: 100lvh;
      opacity: 0.8;
      background-color: ${$isOpen ? primaryColors.c01 : 'transparent'};
      transition: background-color 0.3s ease ${$isOpen ? '0' : '0.3s'};
      pointer-events: ${$isOpen ? 'auto' : 'none'};
    }
  `};
`;

export const StyledSideNav = styled.nav<{ $isOpen: boolean }>`
  ${({ $isOpen }) => `
    position: fixed;
    top: 0;
    left: ${$isOpen ? '0' : '100%'};
    width: 100%;
    height: 100lvh;
    background-color: ${primaryColors.w01};
    z-index: 1000;
    transition: left 0.4s ease 0.3s;
    overflow-y: scroll;

    @media (min-width: ${BREAKPOINTS.smallDesktop}px) {
      width: ${px2Rem(414)};
      left: ${$isOpen ? '0' : '-100%'};
    }

    .side-nav-header {
      display: flex;
      justify-content: space-between;
      background-color: ${primaryColors.b01};
      align-items: center;
      padding: ${px2Rem(28)} ${px2Rem(24)};
      font-weight: ${CSS_FONT_WEIGHT.vars.bold};
      font-size: ${px2Rem(16)};
      line-height: ${px2Rem(19)};
      color: ${primaryColors.w01};

      button {
        background: transparent;
        border: none;
        cursor: pointer;
        padding: 0;
        height: ${px2Rem(24)};
        width: ${px2Rem(24)};
      }
    }
  `};
`;
