const ComputersIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7097_686)">
      <circle cx="24" cy="24" r="24" fill="#F4F4F4" />
      <path d="M31.9997 26V17.3335C31.9997 16.9799 31.8593 16.6408 31.6092 16.3908C31.3592 16.1407 31.02 16.0002 30.6664 16.0002H17.3333C16.9797 16.0002 16.6406 16.1407 16.3905 16.3908C16.1405 16.6408 16 16.9799 16 17.3335V26H31.9997Z" stroke="#ED7C7C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M33.8852 30.1252C33.9754 30.3283 34.0134 30.5506 33.9958 30.772C33.9783 30.9935 33.9056 31.207 33.7846 31.3933C33.6635 31.5795 33.4978 31.7325 33.3025 31.8385C33.1073 31.9444 32.8887 31.9999 32.6665 31.9999H15.3335C15.1113 31.9999 14.8927 31.9444 14.6975 31.8385C14.5022 31.7325 14.3365 31.5795 14.2154 31.3933C14.0944 31.207 14.0217 30.9935 14.0042 30.772C13.9866 30.5506 14.0246 30.3283 14.1148 30.1252L16.0001 26H31.9999L33.8852 30.1252Z" stroke="#ED7C7C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.667 29.3333H25.3336" stroke="#ED7C7C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_7097_686">
        <rect width="48" height="48" rx="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ComputersIcon;
