import { secondaryColors } from './../../variables/colours';
import { px2Rem } from './../../../utils/px2Rem';
import { primaryColors } from './../affordableMobiles/colours';

export const aboutUsPage = {
  heroSection: {
    backgroundColor: primaryColors.p01,
    copyColor: primaryColors.w01,
    button: {
      borderRadius: px2Rem(50),
      color: primaryColors.w01,
      background: primaryColors.o01,
    },
  },
  ourCustomers: {
    headerColor: primaryColors.p01,
    copyColor: primaryColors.c01,
  },
  whyLease: {
    headerColor: primaryColors.p01,
    copyColor: primaryColors.c01,
  },
  howItWorks: {
    stepDisplay: {
      backgroundColor: secondaryColors.b04,
    },
    headerColor: primaryColors.c01,
  },
};
