import { NavDataModel } from '@raylo-tech/raylopay-ui/src/types';
import { NavBarWrapper } from '@raylo-tech/raylopay-ui';
import { useState, useEffect } from 'react';
import { Link } from '../../elements/Link/Link';
import { useRouter } from 'next/router';

const GlobalNavBar = ({
  navData,
  hasAuthenticatedUser,
}: {
  navData: NavDataModel;
  hasAuthenticatedUser: boolean;
}) => {
  /**
   * Reading the `hasLoggedInCustomer` value directly from the context can
   * cause a hydration issue between the server-generated markup and the
   * client-generated markup. We can conditionally show the
   * `ExistingCustomerNavBar` and `AccountBanner` by using a local state to
   * store the value of `hasLoggedInCustomer` and updating it when the context
   * value changes.
   */
  const [localHasLoggedInCustomer, setLocalHasLoggedInCustomer] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setLocalHasLoggedInCustomer(hasAuthenticatedUser);
  }, [hasAuthenticatedUser]);

  if (localHasLoggedInCustomer || router.asPath === '/recommendations') return null;

  return (
    <NavBarWrapper
      accountHomeUrl={process.env.NEXT_PUBLIC_ACCOUNT_BASE_URL!}
      productsUrl=""
      navData={navData}
      LinkComponent={Link}
    />
  );
};

export default GlobalNavBar;
