import { rayloLight } from '../rayloLight';
import { uSwitchColors } from './colours';
export const button = {
  ...rayloLight.button,
  background: {
    ...rayloLight.button.background,
    primary01: uSwitchColors.navy,
    primary02: uSwitchColors.navy,
    primary03: uSwitchColors.navy,
  },
  borderRadius: '0',
  hover: {
    primary02: '#53535e',
    primary03: '#53535e',
  },
};
