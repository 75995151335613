import { captureException } from '@sentry/core';
import { AuthTokenInfo } from './authTokenInfo';
import type { AuthTokenType, AuthTokenFetcher } from './auth.types';

export class AuthTokenProvider {
  private currentToken: AuthTokenInfo | undefined;
  private tokenType: AuthTokenType;

  private getNewToken: AuthTokenFetcher;
  private tokenFetchInProgress: Promise<void> | undefined;

  constructor(tokenType: AuthTokenType, getNewToken: AuthTokenFetcher) {
    this.tokenType = tokenType;
    this.getNewToken = getNewToken;
  }

  get token(): AuthTokenInfo {
    if (!this.currentToken) {
      throw new Error('No current token, use `renewIfNeeded()` to get a new token');
    }
    return this.currentToken;
  }

  async renewIfNeeded(): Promise<void> {
    if (this.tokenFetchInProgress) {
      // ensure a single call to get a new token when multiple Apollo queries are made concurrently
      await this.tokenFetchInProgress;
    }

    if (!this.currentToken || this.currentToken.isExpired()) {
      this.tokenFetchInProgress = new Promise<void>((resolve, reject) => {
        this.getNewToken().then((response) => {
          this.currentToken = new AuthTokenInfo(response.token, response.expiresAt);
          if (response.expiresAt !== undefined && this.currentToken.expiresAt === undefined) {
            captureException(new Error(`could not parse ${this.tokenType} auth token expiry date ('expiresAt' value: '${response.expiresAt}')`));
          }
          this.tokenFetchInProgress = undefined;
          resolve();
        }, (error) => {
          this.tokenFetchInProgress = undefined;
          reject(error);
        });
      });
      await this.tokenFetchInProgress;
    }
  }
}
