import animationData from './animation.json';
import { px2Rem } from '../../../utils/px2Rem';
import LottieAnimation from '../../LottieAnimation';

const AnimatedLogo = () => (
  <a href="https://raylo.com" title="Raylo" style={{ width: px2Rem(132) }}>
    <LottieAnimation animationData={animationData} height={32} width={132} />
  </a>
);

export default AnimatedLogo;
