import { secondaryColors, primaryColors } from './../../variables/colours';

export const navBar = {
  backgroundColor: primaryColors.b01,
  bottomBorder: '0',
  linkColor: primaryColors.w01,
  hoverLinkColor: primaryColors.p01,
  disabledLinkColor: secondaryColors.c03,
  menuBarsColor: primaryColors.p01,
};
